 // @flow
import { Cookies } from 'react-cookie';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { fetchJSON } from '../../helpers/api';

import { LOGIN_USER, LOGOUT_USER, REGISTER_USER, FORGET_PASSWORD } from './constants';

import {
    loginUserSuccess,
    loginUserFailed,
    registerUserSuccess,
    registerUserFailed,
    forgetPasswordSuccess,
    forgetPasswordFailed,
} from './actions';


/**
 * Sets the session
 * @param {*} user
 */



const setSession = user => {
    let cookies = new Cookies();
    if (user) cookies.set('email', JSON.stringify(user), { path: '/' });
    else cookies.remove('email', { path: '/' });
};
/**
 * Login the user
 * @param {*} payload - username and password
 */

function* login({ payload: { email, password } }) {
    const options = {
        body: JSON.stringify({ email, password }),
        method: 'POST',
        headers: {'Accept': 'application/json', 'Content-Type': 'application/json' },
    };



    try {
        const response = yield call(fetchJSON,process.env.REACT_APP_API_LOGIN , options);
        //const response = yield call(fetchJSON,"/api/users/login" , options);
        //const response = yield call(fetchJSON,"http://localhost:3115/api/users/login" , options);

        setSession(response);
        yield put(loginUserSuccess(response));
    } catch (error) {
        let message;

        if (!error.status) {
            error = "Es scheint als wäre der Server gerade nicht verfügbar...bitte einen Moment warten"
        }

        switch (error.status) {
            case 500:
                message = 'Internal Server Error Errorcode: 500';
                break;
            case 401:
                message = 'E-Mail oder Passwort falsch. (Errorcode 401)';
                break;
            case 404:
                message = 'Verbindung zum Server nicht möglich. Bitte warten (Errorcode 404)';
                break;
            default:
                message = error;
        }
        yield put(loginUserFailed(message));
        setSession(null);
    }
}

/**
 * Logout the user
 * @param {*} param0
 */
function* logout({ payload: { history } }) {
    try {
        setSession(null);
        yield call(() => {
            history.push('/account/login');
        });
    } catch (error) {}
}

/**
 * Register the user
 */
function* register({ payload: { fullname, email, password } }) {
    const options = {
        body: JSON.stringify({ fullname, email, password }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        const response = yield call(fetchJSON, '/users/register', options);
        yield put(registerUserSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(registerUserFailed(message));
    }
}

/**
 * forget password
 */
function* forgetPassword({ payload: { email } }) {
    const options = {
        body: JSON.stringify({ email }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        const response = yield call(fetchJSON, '/users/password-reset', options);
       // const response = yield call(fetchJSON, '/users/password-reset', options);
        console.log(response)
        yield put(forgetPasswordSuccess(response.message));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(forgetPasswordFailed(message));
    }
}

export function* watchLoginUser() {
    yield takeEvery(LOGIN_USER, login);
}

export function* watchLogoutUser() {
    yield takeEvery(LOGOUT_USER, logout);
}

export function* watchRegisterUser() {
    yield takeEvery(REGISTER_USER, register);
}

export function* watchForgetPassword() {
    yield takeEvery(FORGET_PASSWORD, forgetPassword);
}

function* authSaga() {
    yield all([fork(watchLoginUser), fork(watchLogoutUser), fork(watchRegisterUser), fork(watchForgetPassword)]);
}

export default authSaga;
